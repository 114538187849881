<template>
    <div>
        <h2 v-if="title != undefined"><v-icon v-if="icon != undefined" left color="#5CD7FD">{{icon}}</v-icon>{{title}}</h2>
        <label v-if="description != undefined">{{description}}</label>
        <v-divider v-if="showDivider" class="pb-2 mb-1"></v-divider>    
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        title: String,
        description: String,
        showDivider: {
            type: Boolean,
            default: true
        }
    }
}
</script>
